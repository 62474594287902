import React from 'react'

import SEO from '../../components/SEO'

import { HeroImage } from '../../components/homeHeroImage'
import HomeIntro from '../../components/homeIntro'
import HomeSectionsIntro from '../../components/homeSectionsIntro'
import ContactFormNew from '../../components/contactFormNew'
import SlyleticaForCreators from '../../components/slyleticaForCreators'
import Illustrations from '../../components/illustrations'
import ProcessBlock from '../../components/processBlock'
import FAQs from '../../components/faqs'
import ShowcaseSlider from '../../components/showcaseSlider'
import Testimonial from '../../components/testimonialBlock'

const Fitness = ({ pageContext }) => {
	const {
		page: { title, fitnessFields },
	} = pageContext

	const {
		fitnessHeaderHeroImage,
		fitnessHeaderHeroImageMobile,
		fitnessHeaderCopy,
		fitnessHeaderCta,
		fitnessIntroCopy,
		fitnessBackgroundColour,
		slyForFitnessHeading,
		fitnessImageCopyRepeater,
		fitnessShowcaseSliderRepeater,
		fitnessIllustrationsRepeater,
		fitnessTestimonialSlider,
		fitnessProcessHeading,
		fitnessProcessRepeater,
		fitnessFaqsHeading,
		fitnessFaqsRepeater,
		fitnessResponsibilityContentPosition,
		fitnessResponsibilityBackgroundImage,
		fitnessResponsibilityHeading,
		fitnessResponsibilityCopy,
		fitnessResponsibilityLink,
		fitnessContactBackgroundColour,
		fitnessContactHeading,
		fitnessContactCopy,
	} = fitnessFields

	return (
		<>
			<SEO title={title} />
			<HeroImage
				image={fitnessHeaderHeroImage}
				mobileImage={fitnessHeaderHeroImageMobile}
				copy={fitnessHeaderCopy}
				cta={fitnessHeaderCta}
			/>
			<HomeIntro
				copy={fitnessIntroCopy}
				backgroundColour={fitnessBackgroundColour}
			/>
			<Illustrations repeater={fitnessIllustrationsRepeater} />
			<Testimonial testimonials={fitnessTestimonialSlider} />
			<ShowcaseSlider brands={fitnessShowcaseSliderRepeater} />
			<ProcessBlock
				heading={fitnessProcessHeading}
				repeater={fitnessProcessRepeater}
			/>
			<SlyleticaForCreators
				heading={slyForFitnessHeading}
				headingStyles={{ width: '100%' }}
				repeater={fitnessImageCopyRepeater}
			/>
			<FAQs heading={fitnessFaqsHeading} faqs={fitnessFaqsRepeater} />
			<HomeSectionsIntro
				heading={fitnessResponsibilityHeading}
				copy={fitnessResponsibilityCopy}
				link={fitnessResponsibilityLink}
				backgroundImage={fitnessResponsibilityBackgroundImage}
				contentPosition={fitnessResponsibilityContentPosition}
				extraHeadingWidth={false}
			/>
			<ContactFormNew
				heading={fitnessContactHeading}
				copy={fitnessContactCopy}
				backgroundColour={fitnessContactBackgroundColour}
			/>
		</>
	)
}

export default Fitness
